//Local API
// export const apiUrl = "http://localhost:8085";
// export const imageUrl = "http://localhost:8085";
// export const agreementfileUrl = "http://localhost:8085/agreementfile";
// export const agreementfilepaymodeUrl = "http://localhost:8085/agreementfilepaymode";
// export const reqeuestMassageFileUrl = "http://localhost:8085/requestMassagefile";
// export const reqeuestMassageImageUrl = "http://localhost:8085/requestMassageimage";

//API Dev
export const apiUrl = "https://api-smemate-dev.dcm.co.th";
export const imageUrl = "https://api-smemate-dev.dcm.co.th";
export const agreementfileUrl = "https://api-smemate-dev.dcm.co.th/agreementfile";
export const agreementfilepaymodeUrl = "https://api-smemate-dev.dcm.co.th/agreementfilepaymode";
export const reqeuestMassageFileUrl = "https://api-smemate-dev.dcm.co.th/requestMassagefile";
export const reqeuestMassageImageUrl = "https://api-smemate-dev.dcm.co.th/requestMassageimage";

// export const apiUrl = "https://api-smemate.dcm.co.th";
// export const imageUrl = "https://api-smemate.dcm.co.th";
// export const agreementfileUrl = "https://api-smemate.dcm.co.th/agreementfile";
// export const agreementfilepaymodeUrl = "https://api-smemate.dcm.co.th/agreementfilepaymode";
// export const reqeuestMassageFileUrl = "https://api-smemate.dcm.co.th/requestMassagefile";
// export const reqeuestMassageImageUrl = "https://api-smemate.dcm.co.th/requestMassageimage";



// Success Page
export const LOGIN_FETCHING = "LOGIN_FETCHING";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGOUT = "LOGOUT";

// Register Page
export const REGISTER_FETCHING = "REGISTER_FETCHING";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAILED = "REGISTER_FAILED";
export const REGISTER_USERNAME_FAILED = "REGISTER_USERNAME_FAILED";

// Verify email Page
export const VERIFYEMAIL_FETCHING = "VERIFYEMAIL_FETCHING";
export const VERIFYEMAIL_SUCCESS = "VERIFYEMAIL_SUCCESS";
export const VERIFYEMAIL_FAILED = "VERIFYEMAIL_FAILED";
export const VERIFY_CHECK_EMAIL_SUCCESS = "VERIFY_CHECK_EMAIL_SUCCESS";
export const VERIFY_CHECK_EMAIL_FAIL = "VERIFY_CHECK_EMAIL_FAIL";

// Forgot_Password Page
export const FORGOT_CHECK_MAIL_FETCHING = "FORGOT_CHECK_MAIL_FETCHING";
export const FORGOT_CHECK_MAIL_SUCCESS = "FORGOT_CHECK_MAIL_SUCCESS";
export const FORGOT_CHECK_MAIL_FAILED = "FORGOT_CHECK_MAIL_FAILED";
export const FORGOT_SEND_MAIL_FETCHING = "FORGOT_SEND_MAIL_FETCHING";
export const FORGOT_SEND_MAIL_SUCCESS = "FORGOT_SEND_MAIL_SUCCESS";
export const FORGOT_SEND_MAIL_FAILED = "FORGOT_SEND_MAIL_FAILED";

// Forgot_Reset_Password Page
export const FORGOT_RESET_FETCHING = "FORGOT_RESET_FETCHING";
export const FORGOT_RESET_SUCCESS = "FORGOT_RESET_SUCCESS";
export const FORGOT_RESET_FAILED = "FORGOT_RESET_FAILED";

// Reset Page
export const RESET_CHECK_PASSWORD_MATCH_SUCCESS = "RESET_CHECK_PASSWORD_MATCH_SUCCESS";
export const RESET_CHECK_PASSWORD_MATCH_FAILED = "RESET_CHECK_PASSWORD_MATCH_FAILED";
export const RESET_UPDATE_NEW_PASSWORD_SUCCESS = "RESET_UPDATE_NEW_PASSWORD_SUCCESS";
export const RESET_UPDATE_NEW_PASSWORD_FAILED = "RESET_UPDATE_NEW_PASSWORD_FAILED";

// Request Page
export const REQUEST_FETCHING = "REQUEST_FETCHING";
export const REQUEST_SUCCESS = "REQUEST_SUCCESS";
export const REQUEST_FAILED = "REQUEST_FAILED";
export const REQUEST_COUNT_DRAFT = "REQUEST_COUNT_DRAFT";
export const REQUEST_CLEAR = "REQUEST_CLEAR";

// Request Assign Page
export const REQUEST_ASSIGN_FETCHING = "REQUEST_ASSIGN_FETCHING";
export const REQUEST_ASSIGN_SUCCESS = "REQUEST_ASSIGN_SUCCESS";
export const REQUEST_ASSIGN_FAILED = "REQUEST_ASSIGN_FAILED";
export const REQUEST_ASSIGN_LEVEL_SUCCESS = "REQUEST_ASSIGN_LEVEL_SUCCESS";
export const REQUEST_ASSIGN_LEVEL_FAILED = "REQUEST_ASSIGN_LEVEL_FAILED";

// Request Log Page
export const REQUEST_LOG_FETCHING = "REQUEST_LOG_FETCHING";
export const REQUEST_LOG_SUCCESS = "REQUEST_LOG_SUCCESS";
export const REQUEST_LOG_FAILED = "REQUEST_LOG_FAILED";


// RequestTracking Page
export const REQUEST_TRACKING_FETCHING = "REQUEST_TRACKING_FETCHING";
export const REQUEST_TRACKING_SUCCESS = "REQUEST_TRACKING_SUCCESS";
export const REQUEST_TRACKING_FAILED = "REQUEST_TRACKING_FAILED";
export const REQUEST_TRACKING_CLEAR = "REQUEST_TRACKING_CLEAR";


// Request Create Page
export const COMPANY_LIST_FETCHING = "COMPANY_LIST_FETCHING";
export const COMPANY_LIST_SUCCESS = "COMPANY_LIST_SUCCESS";
export const COMPANY_LIST_FAILED = "COMPANY_LIST_FAILED";
export const REQUEST_TYPE_LIST_FETCHING = "REQUEST_TYPE_LIST_FETCHING";
export const REQUEST_TYPE_LIST_SUCCESS = "REQUEST_TYPE_LIST_SUCCESS";
export const REQUEST_TYPE_LIST_FAILED = "REQUEST_TYPE_LIST_FAILED";
export const REQUEST_TYPE_ADD_SUCCESS = "REQUEST_TYPE_ADD_SUCCESS";
export const REQUEST_TYPE_ADD_FAILED = "REQUEST_TYPE_ADD_FAILED";
export const REQUEST_TYPE_EDIT_SUCCESS = "REQUEST_TYPE_EDIT_SUCCESS";
export const REQUEST_TYPE_EDIT_FAILED = "REQUEST_TYPE_EDIT_FAILED";
export const REQUEST_TYPE_DELETE_SUCCESS = "REQUEST_TYPE_DELETE_SUCCESS";
export const REQUEST_TYPE_DELETE_FAILED = "REQUEST_TYPE_DELETE_FAILED";
export const REQUEST_SUB_TYPE_LIST_FETCHING = "REQUEST_SUB_TYPE_LIST_FETCHING";
export const REQUEST_SUB_TYPE_LIST_SUCCESS = "REQUEST_SUB_TYPE_LIST_SUCCESS";
export const REQUEST_SUB_TYPE_LIST_FAILED = "REQUEST_SUB_TYPE_LIST_FAILED";
export const REQUEST_SUB_TYPE_ADD_SUCCESS = "REQUEST_SUB_TYPE_ADD_SUCCESS";
export const REQUEST_SUB_TYPE_ADD_FAILED = "REQUEST_SUB_TYPE_ADD_FAILED";
export const REQUEST_SUB_TYPE_EDIT_SUCCESS = "REQUEST_SUB_TYPE_EDIT_SUCCESS";
export const REQUEST_SUB_TYPE_EDIT_FAILED = "REQUEST_SUB_TYPE_EDIT_FAILED";
export const REQUEST_SUB_TYPE_DELETE_SUCCESS = "REQUEST_SUB_TYPE_DELETE_SUCCESS";
export const REQUEST_SUB_TYPE_DELETE_FAILED = "REQUEST_SUB_TYPE_DELETE_FAILED";
export const REQUEST_CREATE_FETCHING = "REQUEST_CREATE_FETCHING";
export const REQUEST_CREATE_SUCCESS = "REQUEST_CREATE_SUCCESS";
export const REQUEST_CREATE_FAILED = "REQUEST_CREATE_FAILED";

// Request Edit Page
export const REQUEST_EDIT_TYPE_LIST_FETCHING = "REQUEST_EDIT_TYPE_LIST_FETCHING";
export const REQUEST_EDIT_TYPE_LIST_SUCCESS = "REQUEST_EDIT_TYPE_LIST_SUCCESS";
export const REQUEST_EDIT_TYPE_LIST_FAILED = "REQUEST_EDIT_TYPE_LIST_FAILED";
export const REQUEST_LIST_FETCHING = "REQUEST_LIST_FETCHING";
export const REQUEST_LIST_SUCCESS = "REQUEST_LIST_SUCCESS";
export const REQUEST_LIST_FAILED = "REQUEST_LIST_FAILED";
export const REQUEST_EDIT_SUB_TYPE_LIST_FETCHING = "REQUEST_EDIT_SUB_TYPE_LIST_FETCHING";
export const REQUEST_EDIT_SUB_TYPE_LIST_SUCCESS = "REQUEST_EDIT_SUB_TYPE_LIST_SUCCESS";
export const REQUEST_EDIT_SUB_TYPE_LIST_FAILED = "REQUEST_EDIT_SUB_TYPE_LIST_FAILED";
export const REQUEST_EDIT_FETCHING = "REQUEST_EDIT_FETCHING";
export const REQUEST_EDIT_SUCCESS = "REQUEST_EDIT_SUCCESS";
export const REQUEST_EDIT_FAILED = "REQUEST_EDIT_FAILED";

// Request Delete Page
export const REQUEST_DELETE_FETCHING = "REQUEST_DELETE_FETCHING";
export const REQUEST_DELETE_SUCCESS = "REQUEST_DELETE_SUCCESS";
export const REQUEST_DELETE_FAILED = "REQUEST_DELETE_FAILED";

// Stock Page
export const STOCK_FETCHING = "STOCK_FETCHING";
export const STOCK_SUCCESS = "STOCK_SUCCESS";
export const STOCK_FAILED = "STOCK_FAILED";
export const STOCK_CLEAR = "STOCK_CLEAR";

// Stock Edit Page
export const STOCK_EDIT_FETCHING = "STOCK_EDIT_FETCHING";
export const STOCK_EDIT_SUCCESS = "STOCK_EDIT_SUCCESS";
export const STOCK_EDIT_FAILED = "STOCK_EDIT_FAILED";

// Provinces Page
export const PROVINCES_FETCHING = "PROVINCES_FETCHING";
export const PROVINCES_SUCCESS = "PROVINCES_SUCCESS";
export const PROVINCES_FAILED = "PROVINCES_FAILED";

// Call API
export const CALL_API = "CALL_API";

// DataSheet Create Page
export const DATASHEET_CREATE_FETCHING = "DATASHEET_CREATE_FETCHING";
export const DATASHEET_CREATE_SUCCESS = "DATASHEET_CREATE_SUCCESS";
export const DATASHEET_UPDATE_SUCCESS = "DATASHEET_UPDATE_SUCCESS";
export const DATASHEET_CREATE_FAILED = "DATASHEET_CREATE_FAILED";
export const DATASHEET_CREATE_SHARE_HOLDER_TH = "DATASHEET_CREATE_SHARE_HOLDER_TH";
export const DATASHEET_CREATE_SHARE_HOLDER_EN = "DATASHEET_CREATE_SHARE_HOLDER_EN";
export const DATASHEET_CREATE_DIRECTOR_TH = "DATASHEET_CREATE_DIRECTOR_TH";
export const DATASHEET_CREATE_DIRECTOR_EN = "DATASHEET_CREATE_DIRECTOR_EN";
export const DATASHEET_LIST = "DATASHEET_LIST";
export const DATASHEET_FIND_BY_ID = "DATASHEET_FIND_BY_ID";

// director 
export const DIRECTOR_FETCHING = "DIRECTOR_FETCHING";
export const DIRECTOR_LIST = "DIRECTOR_LIST";

// share_holder 
export const SHARE_HOLDER_FETCHING = "SHARE_HOLDER_FETCHING";
export const SHARE_HOLDER_LIST = "SHARE_HOLDER_LIST";

// Agreement Group Page
export const AGREEMENT_GROUP_FETCHING = "AGREEMENT_GROUP_FETCHING";
export const AGREEMENT_GROUP_SUCCESS = "AGREEMENT_GROUP_SUCCESS";
export const AGREEMENT_GROUP_ADD_SUCCESS = "AGREEMENT_GROUP_ADD_SUCCESS";
export const AGREEMENT_GROUP_ADD_FAILED = "AGREEMENT_GROUP_ADD_FAILED";
export const AGREEMENT_GROUP_EDIT_SUCCESS = "AGREEMENT_GROUP_EDIT_SUCCESS";
export const AGREEMENT_GROUP_EDIT_FAILED = "AGREEMENT_GROUP_EDIT_FAILED";
export const AGREEMENT_GROUP_DELETE_SUCCESS = "AGREEMENT_GROUP_DELETE_SUCCESS";
export const AGREEMENT_GROUP_DELETE_FAILED = "AGREEMENT_GROUP_DELETE_FAILED";
export const AGREEMENT_GROUP_FAILED = "AGREEMENT_GROUP_FAILED";
export const AGREEMENT_GROUP_CLEAR = "AGREEMENT_GROUP_CLEAR";

// Agreement Page
export const AGREEMENT_FETCHING = "AGREEMENT_FETCHING";
export const AGREEMENT_SUCCESS = "AGREEMENT_SUCCESS";
export const AGREEMENT_BYLANG_SUCCESS = "AGREEMENT_BYLANG_SUCCESS";
export const AGREEMENT_ADD_SUCCESS = "AGREEMENT_ADD_SUCCESS";
export const AGREEMENT_ADD_FAILED = "AGREEMENT_ADD_FAILED";
export const AGREEMENT_EDIT_SUCCESS = "AGREEMENT_EDIT_SUCCESS";
export const AGREEMENT_EDIT_FAILED = "AGREEMENT_EDIT_FAILED";
export const AGREEMENT_DELETE_SUCCESS = "AGREEMENT_DELETE_SUCCESS";
export const AGREEMENT_DELETE_FAILED = "AGREEMENT_DELETE_FAILED";
export const AGREEMENT_FAILED = "AGREEMENT_FAILED";
export const AGREEMENT_CLEAR = "AGREEMENT_CLEAR";
export const AGREEMENT_FETCHING_ADDTOCART = "AGREEMENT_FETCHING_ADDTOCART";

// Roles Page
export const ROLES_FETCHING = "ROLES_FETCHING";
export const ROLES_SUCCESS = "ROLES_SUCCESS";
export const ROLES_ADD_SUCCESS = "ROLES_ADD_SUCCESS";
export const ROLES_ADD_FAILED = "ROLES_ADD_FAILED";
export const ROLES_EDIT_SUCCESS = "ROLES_EDIT_SUCCESS";
export const ROLES_EDIT_FAILED = "ROLES_EDIT_FAILED";
export const ROLES_DELETE_SUCCESS = "ROLES_DELETE_SUCCESS";
export const ROLES_DELETE_FAILED = "ROLES_DELETE_FAILED";
export const ROLES_FAILED = "ROLES_FAILED";
export const ROLES_CLEAR = "ROLES_CLEAR";
export const ROLES_FIND_BY_ID = "ROLES_FIND_BY_ID";


// User Page
export const USER_FETCHING = "USER_FETCHING";
export const USER_SUCCESS = "USER_SUCCESS";
export const USER_ADD_SUCCESS = "USER_ADD_SUCCESS";
export const USER_ADD_FAILED = "USER_ADD_FAILED";
export const USER_EDIT_SUCCESS = "USER_EDIT_SUCCESS";
export const USER_EDIT_FAILED = "USER_EDIT_FAILED";
export const USER_DELETE_SUCCESS = "USER_DELETE_SUCCESS";
export const USER_DELETE_FAILED = "USER_DELETE_FAILED";
export const USER_FAILED = "USER_FAILED";
export const USER_CLEAR = "USER_CLEAR";
export const CHECK_USER_NAME_SUCCESS = "CHECK_USER_NAME_SUCCESS";
export const CHECK_USER_NAME_FAILED = "CHECK_USER_NAME_FAILED";

// Request Message Page
export const MESSAGE_FETCHING = "MESSAGE_FETCHING";
export const MESSAGE_SUCCESS = "MESSAGE_SUCCESS";
export const MESSAGE_ADD_SUCCESS = "MESSAGE_ADD_SUCCESS";
export const MESSAGE_ADD_FAILED = "MESSAGE_ADD_FAILED";
export const MESSAGE_EDIT_SUCCESS = "MESSAGE_EDIT_SUCCESS";
export const MESSAGE_EDIT_FAILED = "MESSAGE_EDIT_FAILED";
export const MESSAGE_DELETE_SUCCESS = "MESSAGE_DELETE_SUCCESS";
export const MESSAGE_DELETE_FAILED = "MESSAGE_DELETE_FAILED";
export const MESSAGE_FAILED = "MESSAGE_FAILED";
export const MESSAGE_CLEAR = "MESSAGE_CLEAR";


// General Menu Page
export const GENERALMENU_FETCHING = "GENERALMENU_FETCHING";
export const GENERALMENU_SUCCESS = "GENERALMENU_SUCCESS";
export const GENERALMENU_ADD_SUCCESS = "GENERALMENU_ADD_SUCCESS";
export const GENERALMENU_ADD_FAILED = "GENERALMENU_ADD_FAILED";
export const GENERALMENU_EDIT_SUCCESS = "GENERALMENU_EDIT_SUCCESS";
export const GENERALMENU_EDIT_FAILED = "GENERALMENU_EDIT_FAILED";
export const GENERALMENU_DELETE_SUCCESS = "GENERALMENU_DELETE_SUCCESS";
export const GENERALMENU_DELETE_FAILED = "GENERALMENU_DELETE_FAILED";
export const GENERALMENU_FAILED = "GENERALMENU_FAILED";
export const GENERALMENU_CLEAR = "GENERALMENU_CLEAR";

// Menu Config Page
export const MENU_CONFIG_FETCHING = "MENU_CONFIG_FETCHING";
export const MENU_CONFIG_SUCCESS = "MENU_CONFIG_SUCCESS";
export const MENU_CONFIG_ADD_SUCCESS = "MENU_CONFIG_ADD_SUCCESS";
export const MENU_CONFIG_ADD_FAILED = "MENU_CONFIG_ADD_FAILED";
export const MENU_CONFIG_EDIT_SUCCESS = "MENU_CONFIG_EDIT_SUCCESS";
export const MENU_CONFIG_EDIT_FAILED = "MENU_CONFIG_EDIT_FAILED";
export const MENU_CONFIG_DELETE_SUCCESS = "MENU_CONFIG_DELETE_SUCCESS";
export const MENU_CONFIG_DELETE_FAILED = "MENU_CONFIG_DELETE_FAILED";
export const MENU_CONFIG_FAILED = "MENU_CONFIG_FAILED";
export const MENU_CONFIG_CLEAR = "MENU_CONFIG_CLEAR";
export const MENU_CONFIG_FIND_BY_ID = "MENU_CONFIG_FIND_BY_ID";
export const MENU_CONFIG_FIND_BY_ID_FOR_HEADER = "MENU_CONFIG_FIND_BY_ID_FOR_HEADER";

// Deparmtment Page
export const DepartmentManagement_FETCHING = "DepartmentManagement_FETCHING";
export const DepartmentManagement_SUCCESS = "DepartmentManagement_SUCCESS";
export const DepartmentManagement_ADD_SUCCESS = "DepartmentManagement_ADD_SUCCESS";
export const DepartmentManagement_ADD_FAILED = "DepartmentManagement_ADD_FAILED";
export const DepartmentManagement_EDIT_SUCCESS = "DepartmentManagement_EDIT_SUCCESS";
export const DepartmentManagement_EDIT_FAILED = "DepartmentManagement_EDIT_FAILED";
export const DepartmentManagement_DELETE_SUCCESS = "DepartmentManagement_DELETE_SUCCESS";
export const DepartmentManagement_DELETE_FAILED = "DepartmentManagement_DELETE_FAILED";
export const DepartmentManagement_FAILED = "DepartmentManagement_FAILED";
export const DepartmentManagement_CLEAR = "DepartmentManagement_CLEAR";

// Role Config Request Type Page
export const ROLE_CONFIG_REQUEST_TYPE_FETCHING = "ROLE_CONFIG_REQUEST_TYPE_FETCHING";
export const ROLE_CONFIG_REQUEST_TYPE_SUCCESS = "ROLE_CONFIG_REQUEST_TYPE_SUCCESS";
export const ROLE_CONFIG_REQUEST_TYPE_ADD_SUCCESS = "ROLE_CONFIG_REQUEST_TYPE_ADD_SUCCESS";
export const ROLE_CONFIG_REQUEST_TYPE_ADD_FAILED = "ROLE_CONFIG_REQUEST_TYPE_ADD_FAILED";
export const ROLE_CONFIG_REQUEST_TYPE_EDIT_SUCCESS = "ROLE_CONFIG_REQUEST_TYPE_EDIT_SUCCESS";
export const ROLE_CONFIG_REQUEST_TYPE_EDIT_FAILED = "ROLE_CONFIG_REQUEST_TYPE_EDIT_FAILED";
export const ROLE_CONFIG_REQUEST_TYPE_DELETE_SUCCESS = "ROLE_CONFIG_REQUEST_TYPE_DELETE_SUCCESS";
export const ROLE_CONFIG_REQUEST_TYPE_DELETE_FAILED = "ROLE_CONFIG_REQUEST_TYPE_DELETE_FAILED";
export const ROLE_CONFIG_REQUEST_TYPE_FAILED = "ROLE_CONFIG_REQUEST_TYPE_FAILED";
export const ROLE_CONFIG_REQUEST_TYPE_CLEAR = "ROLE_CONFIG_REQUEST_TYPE_CLEAR";
export const ROLE_CONFIG_REQUEST_TYPE_FIND_BY_ID = "ROLE_CONFIG_REQUEST_TYPE_FIND_BY_ID";

// Personal information Page
export const PERONAL_INFOMATION_FETCHING = "PERONAL_INFOMATION_FETCHING";
export const PERONAL_INFOMATION_SUCCESS = "PERONAL_INFOMATION_SUCCESS";
export const PERONAL_INFOMATION_FAILED = "PERONAL_INFOMATION_FAILED";

// service type Page
export const SERVICE_TYPE_FETCHING = "SERVICE_TYPE_FETCHING";
export const SERVICE_TYPE_SUCCESS = "SERVICE_TYPE_SUCCESS";
export const SERVICE_TYPE_ADD_SUCCESS = "SERVICE_TYPE_ADD_SUCCESS";
export const SERVICE_TYPE_ADD_FAILED = "SERVICE_TYPE_ADD_FAILED";
export const SERVICE_TYPE_EDIT_SUCCESS = "SERVICE_TYPE_EDIT_SUCCESS";
export const SERVICE_TYPE_EDIT_FAILED = "SERVICE_TYPE_EDIT_FAILED";
export const SERVICE_TYPE_DELETE_SUCCESS = "SERVICE_TYPE_DELETE_SUCCESS";
export const SERVICE_TYPE_DELETE_FAILED = "SERVICE_TYPE_DELETE_FAILED";
export const SERVICE_TYPE_FAILED = "SERVICE_TYPE_FAILED";
export const SERVICE_TYPE_CLEAR = "SERVICE_TYPE_CLEAR";
export const SERVICE_TYPE_SUCCESS_All_SERVICE = "SERVICE_TYPE_SUCCESS_All_SERVICE";

// stripe patment 
export const STRIPE_PAYMENT_FETCHING = "STRIPE_PAYMENT_FETCHING";
export const STRIPE_PAYMENT_SUCCESS = "STRIPE_PAYMENT_SUCCESS";
export const STRIPE_PAYMENT_ADD_SUCCESS = "STRIPE_PAYMENT_ADD_SUCCESS";
export const STRIPE_PAYMENT_ADD_FAILED = "STRIPE_PAYMENT_ADD_FAILED";
export const STRIPE_PAYMENT_EDIT_SUCCESS = "STRIPE_PAYMENT_EDIT_SUCCESS";
export const STRIPE_PAYMENT_EDIT_FAILED = "STRIPE_PAYMENT_EDIT_FAILED";
export const STRIPE_PAYMENT_DELETE_SUCCESS = "STRIPE_PAYMENT_DELETE_SUCCESS";
export const STRIPE_PAYMENT_DELETE_FAILED = "STRIPE_PAYMENT_DELETE_FAILED";
export const STRIPE_PAYMENT_FAILED = "STRIPE_PAYMENT_FAILED";
export const STRIPE_PAYMENT_CLEAR = "STRIPE_PAYMENT_CLEAR";

// login logs
export const LOGIN_LOGS_FETCHING = "LOGIN_LOGS_FETCHING";
export const LOGIN_LOGS_SUCCESS = "LOGIN_LOGS_SUCCESS";
export const LOGIN_LOGS_FAILED = "LOGIN_LOGS_FAILED";




export const YES = "YES";
export const NO = "NO";
export const OK = "ok";
export const NOK = "nok";
export const TOKEN = "TOKEN";
export const MEMBER_ID = "MEMBER_ID";

export const LOGIN_STATUS = "LOGIN_STATUS";

export const server = {
  LOGIN_URL: `member/login`,
  REGISTER_URL: `register`,
  PRODUCT_URL: `stock/product`,
  TRANSACTION_URL: `transaction`,
  REPORT_URL: `stock/report`,
  LOGIN_PASSED: `yes`,
  MEMBER_URL: `member`,
  DATASHEET_URL: `datasheet`,
  FORGOT_PASS_URL: `forgot-password`,
  FORGOT_PASS_RESET_URL: `forgot-reset-password`,
  PROVINCES_URL: `provinces`,
  CONFIRM_EMAIL_URL: `confirm_email`,
  REQUEST: `request`,
  REQUEST_DETAIL: `request-detail`,
  REQUEST_TYPE: `request-type`,
  REQUEST_SUB_TYPE: `request-sub-type`,
  REQUEST_ASSIGN: `request_assign`,
  REQUEST_LOG: `request_log`,
  DOC_RUNNING: `doc-running`,
  SHARE_HOLDER: `share_holder`,
  DIRECTOR: `director`,
  AGREEMENT_GROUP: `agreement_group`,
  AGREEMENT: `agreement`,
  ROLES: `roles`,
  User: `user`,
  Massage: `request-message`,
  GENERAL_MENU: `general_memu`,
  MENU_CONFIG: `menu_config`,
  DEPARTMENT: `department`,
  ROLE_CONFIG_REQUEST_TYPE: `role_config_request_type`,
  SERVICE_TYPE:`service_type`,
  STRIPE_PAYMENT:`stripe_payment`,
  LOGINOUTLOG:`loginoutlog`
  
};

// Error Code
export const E_PICKER_CANCELLED = "E_PICKER_CANCELLED";
export const E_PICKER_CANNOT_RUN_CAMERA_ON_SIMULATOR = "E_PICKER_CANNOT_RUN_CAMERA_ON_SIMULATOR";
export const E_PERMISSION_MISSING = "E_PERMISSION_MISSING";
export const E_PICKER_NO_CAMERA_PERMISSION = "E_PICKER_NO_CAMERA_PERMISSION";
export const E_USER_CANCELLED = "E_USER_CANCELLED";
export const E_UNKNOWN = "E_UNKNOWN";
export const E_DEVELOPER_ERROR = "E_DEVELOPER_ERROR";
export const TIMEOUT_NETWORK = "ECONNABORTED"; // request service timeout
export const NOT_CONNECT_NETWORK = "NOT_CONNECT_NETWORK";

//////////////// Localization Begin ////////////////
export const NETWORK_CONNECTION_MESSAGE = "Cannot connect to server, Please try again.";
export const NETWORK_TIMEOUT_MESSAGE = "A network timeout has occurred, Please try again.";
export const UPLOAD_PHOTO_FAIL_MESSAGE = "An error has occurred. The photo was unable to upload.";

